.chat-bubble {
  border-radius: 8px;
  font-size: 16px;
  padding: 10px;
  position: relative;
}

.chat-bubble-row {
  padding: 10px 10px;
  align-items: center;
}

.user-bubble-color {
  background-color: #fff;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  color: #0a3273;
}

.client-bubble-color {
  background-color: #0a3273;
  color: #fff;
}

.user-felxFirection {
  flex-direction: row;
}

.client-felxFirection {
  flex-direction: row-reverse;
}

.chat-box {
  height: 450px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
